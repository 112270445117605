<template>
  <b-container class="cd-left__panel">
    <div v-html="getSelectedCourse.overview"></div>
  </b-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getSelectedCourse"])
  }
};
</script>
<style lang="scss" scoped>
.cd-left__panel {
  text-align: left;
  padding: 0;
}
// rtl layout
[dir="rtl"] {
  .cd-left__panel {
    text-align: right;
    padding: 0;
  }
}
</style>
